body{
  width: 100%;
  overflow-x: hidden;
  background-color: #FFF3C8;
}

.pattern{
  position: absolute;
}

.logo{
  left:20px;
  top:20px;
  z-index: 2;
}

.logo img{
  height: 70px;
}

.headerwhite{
  left: 0;
  top:0;
  z-index: 1;
}

.brewyourown{
  left:20px;
  top:140px;
}

.brewyourown img{
  height: 95px;
}

.banner-first {
  /* top: -125px; */
  right: 0;
  top:0;
  z-index: 1;
  animation: firstUpDown 4s linear infinite;
}

.banner-first img{
  height: 200px;
}

.header-text{
  margin-top:250px;
  width: 100%;
  text-align: center;
  color: #006241;
  font-size: 24px;
  font-weight: bold;
}

/* h1{
  padding: 20px;
  
} */

.sub-header-text{
  /* padding: 0px 20px; */
  /* font-size:14px; */
  /* line-height: 1.25rem; */
  color: #5A1700;
  /* font-family: sodosans-regular; */
}

.contestant{
  text-align: center;
  border: solid 1px white;
  margin-top: 20px;
  border-radius: 10px;
  background-color: white;
  height: 390px;
}

.contestant img{
  object-fit: cover;
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0 0;
}

.contestant .text{
  height: 135px;
  padding-left: 5px;
  padding-right: 5px;
}
.contestant .shortDescription{
  margin-top: 5px;
  color: #006241;
  font-size: 10px;
  font-weight: bold;
}

.contestant .longDescription{
    color: #5A1700;
    font-size: 10px;
    padding-top: 2px;
}

.contestant .button{
  padding-left: 5px;
  padding-right: 5px;
}

.contestant button{
  width: 100%;
  background-color: #FFF3C8;
    border: 1px solid #FA9D24;
    padding: 0.7rem 1.2rem;
    padding-top: 0.9rem;
    border-radius: 30px;
    font-size: 1.1rem;
    line-height: 24px;
    cursor: pointer;
    color: rgba(57, 58, 50, 0.6);
    text-transform: uppercase;    
}

.contestant .selected-contestant{
  border: 1px solid #FA9D24;
  background-color:#FA9D24;
  color: white;
}

.inactive{
  background: rgba(103, 118, 140, 0.5);
  height: 390px;
  text-align: center;
  border-radius: 10px;
  
}

.inactive span{
  color: white;
  position: relative;
  top: 45%;
  font-weight: bold;
  /* left: 50%;
  transform: translate(-50%, -50%); */
  width:100%;
}


.footer{
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 5px;
  background-color: white;
}

.footer button{
  color: #fff;
    border-radius: 30px;
    padding: 1rem 1.8rem;
    /* font-family: lemon-days; */
    background-color: #006241;
    border:none;
    width: 100%;
    text-transform: uppercase;
    /* font-size: ; */
}

.info{
  color: #5A1700;
  text-align: center;
  width: 100%;
  font-style: italic;
}